const Index = () => import('@/pages/website/IndexPage.vue');
const AboutUs = () => import('@/pages/website/AboutUsPage.vue');
const Press = () => import('@/pages/website/PressPage.vue');
const Contact = () => import('@/pages/website/ContactPage.vue');
const TermsOfService = () => import('@/pages/website/TermsOfServicePage.vue');
const Privacy = () => import('@/pages/website/PrivacyPage.vue');
const Blog1 = () => import('@/pages/website/blog/BlogPage1.vue');
const Blog2 = () => import('@/pages/website/blog/BlogPage2.vue');
const Blog3 = () => import('@/pages/website/blog/BlogPage3.vue');
const Blog4 = () => import('@/pages/website/blog/BlogPage4.vue');
const Blog5 = () => import('@/pages/website/blog/BlogPage5.vue');
const Blog6 = () => import('@/pages/website/blog/BlogPage6.vue');
const Blog7 = () => import('@/pages/website/blog/BlogPage7.vue');
const Blog8 = () => import('@/pages/website/blog/BlogPage8.vue');
const Blog9 = () => import('@/pages/website/blog/BlogPage9.vue');
const Blog10 = () => import('@/pages/website/blog/BlogPage10.vue');
const Blog11 = () => import('@/pages/website/blog/BlogPage11.vue');
const Blog12 = () => import('@/pages/website/blog/BlogPage12.vue');
const Blog13 = () => import('@/pages/website/blog/BlogPage13.vue');
const Blog14 = () => import('@/pages/website/blog/BlogPage14.vue');
const Blog15 = () => import('@/pages/website/blog/BlogPage15.vue');
const Blog16 = () => import('@/pages/website/blog/BlogPage16.vue');
const Blog17 = () => import('@/pages/website/blog/BlogPage17.vue');
const Blog18 = () => import('@/pages/website/blog/BlogPage18.vue');
const Blog19 = () => import('@/pages/website/blog/BlogPage19.vue');
const Blog20 = () => import('@/pages/website/blog/BlogPage20.vue');
const Blog21 = () => import('@/pages/website/blog/BlogPage21.vue');
const Blog22 = () => import('@/pages/website/blog/BlogPage22.vue');
const Blog23 = () => import('@/pages/website/blog/BlogPage23.vue');
const Blog24 = () => import('@/pages/website/blog/BlogPage24.vue');
const Blog25 = () => import('@/pages/website/blog/BlogPage25.vue');
const Blog26 = () => import('@/pages/website/blog/BlogPage26.vue');
const Blog27 = () => import('@/pages/website/blog/BlogPage27.vue');
const Blog28 = () => import('@/pages/website/blog/BlogPage28.vue');
const Blog29 = () => import('@/pages/website/blog/BlogPage29.vue');
const Blog30 = () => import('@/pages/website/blog/BlogPage30.vue');
const Blog31 = () => import('@/pages/website/blog/BlogPage31.vue');
const Blog32 = () => import('@/pages/website/blog/BlogPage32.vue');
const Blog33 = () => import('@/pages/website/blog/BlogPage33.vue');
const Blog34 = () => import('@/pages/website/blog/BlogPage34.vue');

const routes = [
  {
    path: '/',
    name: 'index',
    components: {
      default: Index,
    },
  },
  {
    path: '/our-team',
    components: {
      default: AboutUs,
    },
    beforeEnter() {
      // Put the full page URL including the protocol http(s) below
      window.location.replace('https://contact.scenera.net/our-team/');
    },
  },
  {
    path: '/press',
    components: {
      default: Press,
    },
    beforeEnter() {
      // Put the full page URL including the protocol http(s) below
      window.location.replace('https://contact.scenera.net/press/');
    },
  },
  {
    path: '/contact-us',
    components: {
      default: Contact,
    },
    beforeEnter() {
      // Put the full page URL including the protocol http(s) below
      window.location.replace('https://contact.scenera.net/contact-us/');
    },
  },
  {
    path: '/terms-of-service',
    components: {
      default: TermsOfService,
    },
    beforeEnter() {
      // Put the full page URL including the protocol http(s) below
      window.location.replace('https://contact.scenera.net/terms-of-service/');
    },
  },
  {
    path: '/privacy',
    components: {
      default: Privacy,
    },
    beforeEnter() {
      // Put the full page URL including the protocol http(s) below
      window.location.replace('https://contact.scenera.net/privacy/');
    },
  },
  {
    path: '/nice-alliance-announces-key-specifications-to-bring-the-next-generation-of-smart-cameras-to-market',
    components: {
      default: Blog1,
    },
    beforeEnter() {
      // Put the full page URL including the protocol http(s) below
      window.location.replace(
        'https://contact.scenera.net/nice-alliance-announces-key-specifications-to-bring-the-next-generation-of-smart-cameras-to-market/',
      );
    },
  },
  {
    path: '/mastering-ai-data-management-unveiling-the-power-of-scenera-maistro',
    components: {
      default: Blog2,
    },
    beforeEnter() {
      // Put the full page URL including the protocol http(s) below
      window.location.replace(
        'https://contact.scenera.net/mastering-ai-data-management-unveiling-the-power-of-scenera-maistro',
      );
    },
  },
  {
    path: '/elevating-your-data-strategy-with-boundless-possibilities-cloud-storage-bound-data-vs-independent-data-layer/',
    components: {
      default: Blog3,
    },
    beforeEnter() {
      // Put the full page URL including the protocol http(s) below
      window.location.replace(
        'https://contact.scenera.net/elevating-your-data-strategy-with-boundless-possibilities-cloud-storage-bound-data-vs-independent-data-layer/',
      );
    },
  },
  {
    path: '/gs25-retail-success-story',
    components: {
      default: Blog4,
    },
    beforeEnter() {
      // Put the full page URL including the protocol http(s) below
      window.location.replace('https://contact.scenera.net/gs25-retail-success-story');
    },
  },
  {
    path: '/strategic-alliance-between-scenera-and-si-corp-sparks-synergies-in-joint-product-development-and-gtm-strategies/',
    components: {
      default: Blog5,
    },
    beforeEnter() {
      // Put the full page URL including the protocol http(s) below
      window.location.replace(
        'https://contact.scenera.net/strategic-alliance-between-scenera-and-si-corp-sparks-synergies-in-joint-product-development-and-gtm-strategies/',
      );
    },
  },
  {
    path: '/scenera-si-seek-to-replicate-facilities-management-platform-model-from-south-korea-in-the-us/',
    components: {
      default: Blog6,
    },
    beforeEnter() {
      // Put the full page URL including the protocol http(s) below
      window.location.replace(
        'https://contact.scenera.net/scenera-si-seek-to-replicate-facilities-management-platform-model-from-south-korea-in-the-us/',
      );
    },
  },
  {
    path: '/nrf-2024-scenera-and-wachter-announce-strategic-collaboration-for-hybrid-cloud-solutions-to-transform-the-future-of-retail/',
    components: {
      default: Blog7,
    },
    beforeEnter() {
      // Put the full page URL including the protocol http(s) below
      window.location.replace(
        'https://contact.scenera.net/nrf-2024-scenera-and-wachter-announce-strategic-collaboration-for-hybrid-cloud-solutions-to-transform-the-future-of-retail/',
      );
    },
  },
  {
    path: '/revolutionizing-energy-data-management-in-convenience-stores-with-sceneras-maistro/',
    components: {
      default: Blog8,
    },
    beforeEnter() {
      // Put the full page URL including the protocol http(s) below
      window.location.replace(
        'https://contact.scenera.net/revolutionizing-energy-data-management-in-convenience-stores-with-sceneras-maistro/',
      );
    },
  },
  {
    path: '/facility-management-must-have-sceneras-ai-and-iot-framework-integration/',
    components: {
      default: Blog9,
    },
    beforeEnter() {
      // Put the full page URL including the protocol http(s) below
      window.location.replace(
        'https://contact.scenera.net/facility-management-must-have-sceneras-ai-and-iot-framework-integration/',
      );
    },
  },
  {
    path: '/scenera-to-acquire-tnm-ai/',
    components: {
      default: Blog10,
    },
    beforeEnter() {
      // Put the full page URL including the protocol http(s) below
      window.location.replace('https://contact.scenera.net/scenera-to-acquire-tnm-ai/');
    },
  },
  {
    path: '/revolutionizing-industries-with-sceneras-ai-powered-iot-smart-device-data-analytics-management-platform',
    components: {
      default: Blog11,
    },
    beforeEnter() {
      // Put the full page URL including the protocol http(s) below
      window.location.replace(
        'https://contact.scenera.net/revolutionizing-industries-with-sceneras-ai-powered-iot-smart-device-data-analytics-management-platform',
      );
    },
  },
  {
    path: '/linux-foundation-telco-api-project-camara-graduates-to-funded-model-with-strong-industry-commitment/',
    components: {
      default: Blog12,
    },
    beforeEnter() {
      // Put the full page URL including the protocol http(s) below
      window.location.replace(
        'https://contact.scenera.net/linux-foundation-telco-api-project-camara-graduates-to-funded-model-with-strong-industry-commitment/',
      );
    },
  },
  {
    path: '/david-lee-of-scenera-on-the-future-of-the-internet-of-things-iot-and-how-it-may-improve-our-health-our-lives',
    components: {
      default: Blog13,
    },
    beforeEnter() {
      // Put the full page URL including the protocol http(s) below
      window.location.replace(
        'https://contact.scenera.net/david-lee-of-scenera-on-the-future-of-the-internet-of-things-iot-and-how-it-may-improve-our-health-our-lives',
      );
    },
  },
  {
    path: '/how-ai-data-analytics-integration-propels-hybrid-work-models-to-success/',
    components: {
      default: Blog14,
    },
    beforeEnter() {
      // Put the full page URL including the protocol http(s) below
      window.location.replace(
        'https://contact.scenera.net/how-ai-data-analytics-integration-propels-hybrid-work-models-to-success/',
      );
    },
  },
  {
    path: '/six-lessons-how-edge-ai-is-evolving-current-state-proptech-industry-commercial-realestate/',
    components: {
      default: Blog15,
    },
    beforeEnter() {
      // Put the full page URL including the protocol http(s) below
      window.location.replace(
        'https://contact.scenera.net/six-lessons-how-edge-ai-is-evolving-current-state-proptech-industry-commercial-realestate/',
      );
    },
  },
  {
    path: '/sceneras-ai-topology-management-service-maistro-demonstrates-capabilities-at-docomo-rd-open-lab-odaiba/',
    components: {
      default: Blog16,
    },
    beforeEnter() {
      // Put the full page URL including the protocol http(s) below
      window.location.replace(
        'https://contact.scenera.net/sceneras-ai-topology-management-service-maistro-demonstrates-capabilities-at-docomo-rd-open-lab-odaiba/',
      );
    },
  },
  {
    path: '/how-commercial-real-estate-can-use-data-to-drive-efficiency-sustainability-and-security-for-buildings-and-lives-as-the-workplace-evolves/',
    components: {
      default: Blog17,
    },
    beforeEnter() {
      // Put the full page URL including the protocol http(s) below
      window.location.replace(
        'https://contact.scenera.net/how-commercial-real-estate-can-use-data-to-drive-efficiency-sustainability-and-security-for-buildings-and-lives-as-the-workplace-evolves/',
      );
    },
  },
  {
    path: '/ai-edge-computing/',
    components: {
      default: Blog18,
    },
    beforeEnter() {
      // Put the full page URL including the protocol http(s) below
      window.location.replace('https://contact.scenera.net/ai-edge-computing/');
    },
  },
  {
    path: '/scenera-and-blaize-demonstrate-sceneras-ai-topology-management-service-satm-for-hybrid-cloud-solutions-in-collaboration-with-marketplace-on-aitrios-at-isc-west-2023/',
    components: {
      default: Blog19,
    },
    beforeEnter() {
      // Put the full page URL including the protocol http(s) below
      window.location.replace(
        'https://contact.scenera.net/scenera-and-blaize-demonstrate-sceneras-ai-topology-management-service-satm-for-hybrid-cloud-solutions-in-collaboration-with-marketplace-on-aitrios-at-isc-west-2023/',
      );
    },
  },
  {
    path: '/scenera-introduces-new-maistro-ai-topology-management-satm-for-aitrios-in-partnership-with-sony-semiconductor-solutions-at-mwc-2023/',
    components: {
      default: Blog20,
    },
    beforeEnter() {
      // Put the full page URL including the protocol http(s) below
      window.location.replace(
        'https://contact.scenera.net/scenera-introduces-new-maistro-ai-topology-management-satm-for-aitrios-in-partnership-with-sony-semiconductor-solutions-at-mwc-2023/',
      );
    },
  },
  {
    path: '/ai-takes-property-security-video-monitoring-to-next-level/',
    components: {
      default: Blog21,
    },
    beforeEnter() {
      // Put the full page URL including the protocol http(s) below
      window.location.replace(
        'https://contact.scenera.net/ai-takes-property-security-video-monitoring-to-next-level/',
      );
    },
  },
  {
    path: '/nice-alliance-announces-new-adopters-toyo-media-links-and-headwaters-which-accelerate-the-expansion-of-advanced-ai-based-secure-services-of-nice-ecosystem-across-industries/',
    components: {
      default: Blog22,
    },
    beforeEnter() {
      // Put the full page URL including the protocol http(s) below
      window.location.replace(
        'https://contact.scenera.net/nice-alliance-announces-new-adopters-toyo-media-links-and-headwaters-which-accelerate-the-expansion-of-advanced-ai-based-secure-services-of-nice-ecosystem-across-industries/',
      );
    },
  },
  {
    path: '/si-building-management-application/',
    components: {
      default: Blog23,
    },
    beforeEnter() {
      // Put the full page URL including the protocol http(s) below
      window.location.replace('https://contact.scenera.net/si-building-management-application/');
    },
  },
  {
    path: '/scenera-ai-topology-manager-satm-orchestrates-commodity-ai-across-edge-to-cloud-platforms-and-enables-dynamic-processing-across-multi-layered-platforms/',
    components: {
      default: Blog24,
    },
    beforeEnter() {
      // Put the full page URL including the protocol http(s) below
      window.location.replace(
        'https://contact.scenera.net/scenera-ai-topology-manager-satm-orchestrates-commodity-ai-across-edge-to-cloud-platforms-and-enables-dynamic-processing-across-multi-layered-platforms/',
      );
    },
  },
  {
    path: '/achieving-edge-to-cloud-processing-fordx-with-ai-capabilities-in-facility-management/',
    components: {
      default: Blog25,
    },
    beforeEnter() {
      // Put the full page URL including the protocol http(s) below
      window.location.replace(
        'https://contact.scenera.net/achieving-edge-to-cloud-processing-fordx-with-ai-capabilities-in-facility-management/',
      );
    },
  },
  {
    path: '/nice-alliance-welcomes-total-building-solution-provider-si-corp-as-adopter-for-global-expansion-of-advanced-ai-based-facility-management-fm-service/',
    components: {
      default: Blog26,
    },
    beforeEnter() {
      // Put the full page URL including the protocol http(s) below
      window.location.replace(
        'https://contact.scenera.net/nice-alliance-welcomes-total-building-solution-provider-si-corp-as-adopter-for-global-expansion-of-advanced-ai-based-facility-management-fm-service/',
      );
    },
  },
  {
    path: '/scenera-sets-the-new-standard-for-distributed-ai-over-5g-powered-by-microsoft-azure/',
    components: {
      default: Blog27,
    },
    beforeEnter() {
      // Put the full page URL including the protocol http(s) below
      window.location.replace(
        'https://contact.scenera.net/scenera-sets-the-new-standard-for-distributed-ai-over-5g-powered-by-microsoft-azure/',
      );
    },
  },
  {
    path: '/linux-foundation-announces-new-project-camara-the-telco-global-api-alliance-with-global-industry-ecosystem/',
    components: {
      default: Blog28,
    },
    beforeEnter() {
      // Put the full page URL including the protocol http(s) below
      window.location.replace(
        'https://contact.scenera.net/linux-foundation-announces-new-project-camara-the-telco-global-api-alliance-with-global-industry-ecosystem/',
      );
    },
  },
  {
    path: '/si-corp-launches-security-and-safety-management-system-built-on-microsoft-azure/',
    components: {
      default: Blog29,
    },
    beforeEnter() {
      // Put the full page URL including the protocol http(s) below
      window.location.replace(
        'https://contact.scenera.net/si-corp-launches-security-and-safety-management-system-built-on-microsoft-azure/',
      );
    },
  },
  {
    path: '/nice-alliance-provides-total-solutions-to-accelerate-expansion-of-ecosystem-with-launch-of-platform-as-a-service-paas-powered-by-microsoft-azure/',
    components: {
      default: Blog30,
    },
    beforeEnter() {
      // Put the full page URL including the protocol http(s) below
      window.location.replace(
        'https://contact.scenera.net/nice-alliance-provides-total-solutions-to-accelerate-expansion-of-ecosystem-with-launch-of-platform-as-a-service-paas-powered-by-microsoft-azure/',
      );
    },
  },
  {
    path: '/nice-specification-announces-foray-into-cloud-platform-service-for-iots-and-ip-cameras/',
    components: {
      default: Blog31,
    },
    beforeEnter() {
      // Put the full page URL including the protocol http(s) below
      window.location.replace(
        'https://contact.scenera.net/nice-specification-announces-foray-into-cloud-platform-service-for-iots-and-ip-cameras/',
      );
    },
  },
  {
    path: '/nice-alliances-cloud-services-to-be-powered-by-microsoft-azure/',
    components: {
      default: Blog32,
    },
    beforeEnter() {
      // Put the full page URL including the protocol http(s) below
      window.location.replace(
        'https://contact.scenera.net/nice-alliances-cloud-services-to-be-powered-by-microsoft-azure/',
      );
    },
  },
  {
    path: '/new-industry-alliance-nice-launched-for-next-generation-smart-ecosystem/',
    components: {
      default: Blog33,
    },
    beforeEnter() {
      // Put the full page URL including the protocol http(s) below
      window.location.replace(
        'https://contact.scenera.net/new-industry-alliance-nice-launched-for-next-generation-smart-ecosystem/',
      );
    },
  },
  {
    path: '/facility-management-ai-solution-with-microsoft-copilot/',
    components: {
      default: Blog34,
    },
    beforeEnter() {
      // Put the full page URL including the protocol http(s) below
      window.location.replace(
        'https://contact.scenera.net/facility-management-ai-solution-with-microsoft-copilot/',
      );
    },
  },
];

export default routes;
