<template lang="">
  <div>
    <footer class="bg-mainGray text-primary dark:bg-gray-900">
      <div class="w-full px-16 md:px-40 py-6 lg:py-8">
        <div class="flex flex-col justify-between sm:flex-row gap-8">
          <div class="mb-0">
            <a href="https://www.scenera.net/" class="pb-6">
              <img src="@/components/asset/scenera-logo.svg" alt="logo" class="object-cover w-40" />
            </a>
            <!-- Social Links -->
            <div class="flex mt-4">
              <a
                href="https://www.facebook.com/scenerainc"
                class="text-gray-500 hover:text-gray-900 dark:hover:text-white"
              >
                <svg
                  class="w-6 h-6"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 8 19"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span class="sr-only">Facebook page</span>
              </a>
              <a
                href="https://x.com/Scenerainc"
                class="text-gray-500 hover:text-gray-900 dark:hover:text-white ms-5"
              >
                <svg
                  class="w-6 h-6"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 17"
                >
                  <path
                    fill-rule="evenodd"
                    d="M20 1.892a8.178 8.178 0 0 1-2.355.635 4.074 4.074 0 0 0 1.8-2.235 8.344 8.344 0 0 1-2.605.98A4.13 4.13 0 0 0 13.85 0a4.068 4.068 0 0 0-4.1 4.038 4 4 0 0 0 .105.919A11.705 11.705 0 0 1 1.4.734a4.006 4.006 0 0 0 1.268 5.392 4.165 4.165 0 0 1-1.859-.5v.05A4.057 4.057 0 0 0 4.1 9.635a4.19 4.19 0 0 1-1.856.07 4.108 4.108 0 0 0 3.831 2.807A8.36 8.36 0 0 1 0 14.184 11.732 11.732 0 0 0 6.291 16 11.502 11.502 0 0 0 17.964 4.5c0-.177 0-.35-.012-.523A8.143 8.143 0 0 0 20 1.892Z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span class="sr-only">Twitter page</span>
              </a>
              <a
                href="https://www.linkedin.com/company/scenerainc/"
                class="text-gray-500 hover:text-gray-900 dark:hover:text-white ms-5"
              >
                <svg
                  class="w-6 h-6"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 50 50"
                  width="50px"
                  height="50px"
                >
                  <path
                    fill-rule="evenodd"
                    d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M17,20v19h-6V20H17z M11,14.47c0-1.4,1.2-2.47,3-2.47s2.93,1.07,3,2.47c0,1.4-1.12,2.53-3,2.53C12.2,17,11,15.87,11,14.47z M39,39h-6c0,0,0-9.26,0-10 c0-2-1-4-3.5-4.04h-0.08C27,24.96,26,27.02,26,29c0,0.91,0,10,0,10h-6V20h6v2.56c0,0,1.93-2.56,5.81-2.56 c3.97,0,7.19,2.73,7.19,8.26V39z"
                  />
                </svg>
                <span class="sr-only">Linkedin page</span>
              </a>
            </div>
          </div>
          <div class="flex flex-row gap-20">
            <!-- Contact -->
            <div>
              <h2 class="mb-6 text-base font-bold text-gray-900 dark:text-white">Contact</h2>
              <div class="text-darkGray font-normal text-wrap max-w-md gap-4 flex flex-col gap-4">
                <div>
                  <p>890 Robb Road</p>
                  <p>Palo Alto, CA 94306</p>
                </div>
                <div>
                  <p>info@scenera.net</p>
                </div>
              </div>
            </div>
            <!-- Quick Link -->
            <div class="flex flex-col md:gap-6 sm:flex-row">
              <ul class="text-darkGray font-normal">
                <li class="mb-4">
                  <a href="https://www.scenera.net/" class="hover:underline">Home</a>
                </li>
                <li class="mb-4">
                  <a href="https://www.scenera.net/our-team/" class="hover:underline">About Us</a>
                </li>
                <!-- <li>
                    <a href="#" class="hover:underline">Features & Benefits</a>
                  </li> -->
                <!-- </ul>
                <ul class="text-darkGray font-normal"> -->
                <!-- <li class="mb-4">
                    <a href="#" class="hover:underline">Applications</a>
                  </li> -->
                <li class="mb-4">
                  <a href="https://www.scenera.net/press/" class="hover:underline">Press</a>
                </li>
                <li>
                  <a href="https://www.scenera.net/contact-us/" class="hover:underline">Contact</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr class="my-8 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-10" />
        <div class="flex flex-col justify-center gap-4 md:gap-24 sm:flex-row">
          <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400"
            >Copyright © 2024
            <a href="https://www.scenera.net/" class="hover:underline">Scenera Inc.</a> All Rights
            Reserved.</span
          >
          <a
            href="https://www.scenera.net/terms-of-service/"
            class="text-sm text-gray-500 sm:text-center dark:text-gray-400 hover:underline"
          >
            Terms of Service</a
          >
          <a
            href="https://www.scenera.net/privacy/"
            class="text-sm text-gray-500 sm:text-center dark:text-gray-400 hover:underline"
          >
            Privacy Policy</a
          >
        </div>
      </div>
    </footer>
  </div>
</template>
<script setup></script>
<style lang=""></style>
